<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.6052 4.20382C16.2476 3.93205 15.7526 3.93206 15.395 4.20386L2.89592 13.7039C2.45622 14.0381 2.3707 14.6654 2.70489 15.1051C3.03908 15.5448 3.66644 15.6303 4.10614 15.2961L5 14.6168V26.0001C5 26.5523 5.44772 27.0001 6 27.0001H13.2727C13.825 27.0001 14.2727 26.5523 14.2727 26.0001V19.6957H17.7273V26.0001C17.7273 26.5523 18.175 27.0001 18.7273 27.0001H26C26.5523 27.0001 27 26.5523 27 26.0001V14.6153L27.896 15.2962C28.3357 15.6303 28.963 15.5448 29.2972 15.1051C29.6314 14.6653 29.5458 14.038 29.1061 13.7038L16.6052 4.20382ZM25 13.0954L16.0002 6.25603L7 13.0967V25.0001H12.2727V18.6957C12.2727 18.1434 12.7204 17.6957 13.2727 17.6957H18.7273C19.2796 17.6957 19.7273 18.1434 19.7273 18.6957V25.0001H25V13.0954Z"
		></path>
	</svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "IconHome",
});
</script>
